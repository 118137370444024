button {
-webkit-appearance: none; /* Safari and iOS */
-moz-appearance: none; /* Firefox */
appearance: none; /* Other browsers */
outline: none; /* Remove the default outline */
background-color: transparent; /* Set the background color to transparent */
}

body{
margin: 0
}

h1{
margin: 0;
}
h2{
margin: 0;
}
p{
margin: 0;
}



.MainTitle {
font-family: font;
padding-left: calc(0.5*var(--base));
padding-top: calc(5.96*var(--contentScale));
padding-right: calc(2*var(--base));
font-size: calc(3.052*var(--contentScale));
}

.SecondaryTitle {
font-family: font;
padding-left: calc(0.5*var(--base));
padding-top: calc(5.96*var(--contentScale));
padding-right: calc(1.5*var(--base));
font-size: calc(2.441*var(--contentScale));
}

.DescriptionContainer {
font-family: font;
align-self: flex-start;
padding-top: calc(1*var(--contentScale));
font-size: calc(1.563*var(--contentScale));
}

.CTAButton {
align-self: center;
font-family: font;
font-weight: bolder;
width: clamp(5%, 100%, 50vh);;
background: none;
border: solid;
border-style:solid;
color: black;
padding: calc(0.75*var(--base)) 0px;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 4px 0px;
cursor: pointer;
border-color:black;
border-radius: 128px;
font-size: calc(3.815*var(--contentScale));
}

.CTAContainer {
height: 100%;
display: inherit;
flex-direction: column;
padding: calc(0.5*var(--base));
padding-top: 0;
padding-bottom: 0;
justify-content: center;
}

.TextContentContainer {
font-family: font;
height: 100%;
display: inherit;
flex-direction: column;
padding: calc(0.5*var(--base));
padding-top: calc(0.5*var(--base));
padding-bottom: 0;
justify-content: start;
}