.ConsentPage {
  height: 93vh;
  display: flex;
  flex-direction: column;
  width: clamp(5%, 100%, 100vh);
  margin: 0 auto;
}

.ConsentPage .Titles {
  display: flex;
  flex-direction: column;
}

.ConsentPage .CTAContainer {
  height: 100%;
  display: inherit;
  flex-direction: column;
  padding: calc(0.5*var(--base));
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.LearnMoreLink {
  text-decoration: underline;
}

.ButtonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#accept {
  background: black;
  color: #CAA5BE;
}