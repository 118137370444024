.loadingContainer {
    font-family: font;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .loadingContainer h3 {
    margin-left: 50%;
  } */