.DataConsentDetailsPage {
    height: 93vh;
    width: clamp(5%, 100%, 100vh);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

.DataConsentDetailsPage .CTAButton {
  width: 75%
}

.titleDescription {
  font-size: calc(1.953 * var(--contentScale))
}

.textElement {
  padding-top: calc(0.25 * var(--base))
}

.textContentHeader {
  font-size: calc(2.441 * var(--contentScale))
}

.textContent {
  padding-top: calc(0.25 * var(--base));
  font-size: calc(1.953 * var(--contentScale))
}