a {
  color: black;
}

.Footer {
  max-width: calc(15*var(--base));
  padding-left: calc(0.5*var(--base));
  padding-right: calc(0.5*var(--base));
  height: var(--base);
  display: flex;
  flex-direction: column;
  color: black;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
}

.FooterLogoContainer {
  display: inherit;
  justify-content: flex-start;
  align-self: left;
}

.FooterLogo {
  font-size: calc(1.563 * var(--contentScale));
  font-family: logofont;
}

.FooterContent {
  font-family: font;
  font-size: calc(1.563 * var(--contentScale));
  text-decoration: underline;
  margin-bottom: calc(0.1*var(--base));
  display: inherit;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
}